import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer class="footer_widgets">
                <div class="main_footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="main_footer_inner d-flex">
                                    <div class="footer_widget_list">
                                        <div class="footer_logo">
                                            <a href="/"><img aria-label="logo" width="142" src="assets/img/logo/LOGOGitty.svg" alt="" /></a>
                                        </div>
                                        <div class="footer_contact_desc">
                                            <p>Make your dream come true with Gitty</p>
                                        </div>
                                        <div class="footer_social">
                                            <ul class="d-flex">
                                                <li>
                                                    <a aria-label="facebook" class="facebook" href="https://www.facebook.com/">
                                                        <i class="icofont-facebook"></i></a>
                                                </li>
                                                <li>
                                                    <a aria-label="telegram" class="dribbble" href="https://dribbble.com/"><i class="icofont-telegram"></i></a>
                                                </li>
                                                <li>
                                                    <a aria-label="instagram" class="youtube" href="https://www.youtube.com/"><i class="icofont-instagram"></i></a>
                                                </li>
                                                <li>
                                                    <a aria-label="twitter" class="twitter" href="https://twitter.com/"><i class="icofont-twitter"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="footer_widget_list contact">
                                        <h3>Contact</h3>
                                        <div class="footer_contact_info">
                                            <div class="footer_contact_info_list">
                                                <span>Location:</span>
                                                <p> Panipat, Haryana   </p>
                                            </div>
                                            <div class="footer_contact_info_list">
                                                <span>Phone:</span>
                                                <p><a href="tel:7988216993"> +91 798 821 6993 </a></p>
                                            </div>
                                            <div class="footer_contact_info_list">
                                                <span>Email:</span>
                                                <p><a href="mailto:zumbotech@gmail.com">zumbotech@gmail.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer_widget_list footer_list_menu">
                                        <h3>Content</h3>
                                        <div class="footer_menu">
                                            <ul class="">
                                                <li class=""><a href="/">Home</a></li>
                                                <li class=""><a href="/#aboutus">About Us</a></li>
                                                <li class=""><a href="/PrivacyPolicy">Privacy Policy</a></li>
                                                <li class=""><a href="/TermsAndCond">Terms and Conditions</a></li>
                                                <li class=""><a href="/RefundAndCanc"> Return, Refund &amp; Cancellation </a></li>
                                                <li class=""><a href="/TdsPolicy"> TDS Policy </a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer_bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="footer_bottom_inner d-flex justify-content-between">
                                    <div class="copyright_right">
                                        <p>Copyrights © 2024 All rights reserved by <a target="_blank" href="#">Gitty</a></p>
                                    </div>
                                    <div class="footer_bottom_link_menu">
                                        <ul class="d-flex copyright_right">
                                            <li><p>Design & Developed by <a class="text-white" href="https://appinop.com" target="_blank">Appinop Technologies.</a></p></li>
                                        </ul>
                                    </div>

                                    <div class="scroll__top_icon">
                                        <a id="scroll-top" href="#"><img aria-label="scroll-top" width="46" height="40" src="assets/img/icon/scroll-top.webp" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
