import React, { useEffect } from 'react'

const PrivacyPolicy = () => {


    // ********* Scroll Upside ********** //
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <>


            <div class="breadcrumbs_aree breadcrumbs_bg" data-bgimg="assets/img/bg/main_bg.png">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumbs_text text-center">
                                <h2>Privacy Policy</h2>
                                <ul class="d-flex justify-content-center">
                                    <li><a href="/">Home </a></li>
                                    <li> <span>//</span></li>
                                    <li>  Privacy Policy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page_wrapper">
                <div class="container mb-5" >
                    <div class=" align-items justify-content-between">
                        <p> 1. GENERAL</p>

                        <p>1.1 Gitty (“Gitty”, “We”, “Our”, “Us”) is committed to the protection of personal information provided by the users (“You”, “Your”, “User”) to Gitty. You agree that Your use of Gitty mobile application (“App”) Your consent to the collection, retention and use of Your personal information in accordance with the terms of this Privacy Policy (“Privacy Policy”).1.2 This Privacy Policy is an electronic record in the form of an electronic contract formed under the information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature and is a legally binding document between You and Gitty.1.3 We take the privacy of Our Users seriously. We are committed to safeguarding the privacy of Our Users while providing a personalized and valuable service.1.4 No User information is rented or sold to any third party. A high standard of security is maintained by Us for Our User. While We do Our best to protect Your information, particularly with respect to protection of Your personal data, Gitty cannot ensure the security of Your data transmitted via the internet, telephone or any other networks.1.5 Access to the contents available through the App is conditional upon Your approval of this Privacy Policy which should be read together with the terms and conditions of use (“Terms”). You acknowledge that this Privacy Policy, together with our Terms, forms Our agreement with You in relation to Your use of the App (“Agreement”).</p>

                        <p>2. INFORMATION COLLECTED</p>

                        <p>2.1 Personal Information Collected
                            In order to provide the App, We may require You to provide Us with certain information that personally identifies You (“Personal Information“). Personal Information includes the following categories of information:
                            (i) Contact data (such as Your email address, Phone Number); and
                            If You communicate with Us by, for Gitty, e-mail or letter, any information provided in such communication may be collected by Gitty.</p>

                        <p>3. DISCLOSURE OF PERSONAL INFORMATION</p>

                        <p>3.1 We do not disclose Your Personal Information to any third parties other than Gitty’s affiliates or other trusted business or persons, based on strict adherence with Our Privacy Policy and any other appropriate confidentiality and security measures.3.2 We reserve the right to disclose Personal Information if required to do so by law or if We believe that it is necessary to do so to protect and defend the rights, property or personal safety of Gitty, the App, or Our Users.3.3 Details of user i.e. Name of User, Total Winning, Refer amount earned, Total Matched Played by the user will be shown in the Leaderboard section of the Application.</p>

                        <p>4. CONFIDENTIALITY</p>

                        <p>4.1 Except as otherwise provided in this Privacy Policy, We will keep Your Personal Information private and will not share it with third parties, unless We believe in good faith that disclosure of Your Personal Information or any other information We collect about You is necessary for Permitted Use or to:4.1.1 Comply with a court order or other legal process;4.1.2 Protect the rights, property or safety of Gitty or another party;4.1.3 Enforce the Agreement, including Terms; or4.1.4 Respond to claims that any posting or other content violates the rights of third-parties.</p>

                        <p>5. SECURITY</p>

                        <p>5.1 The security of Your Personal Information is important to Us. We follow generally accepted industry standards to protect the Personal Information submitted to Us, both during transmission and once We receive it. All information gathered on Our Website is securely stored within Our controlled database.5.2 Although We make best possible efforts to store Personal Information in a secure operating environment that is not open to the public, You should understand that there is no such thing as complete security, and We do not guarantee that there will be no unintended disclosures of Your Personal Information. If We become aware that Your Personal Information has been disclosed in a manner not in accordance with this Privacy Policy, We will use reasonable efforts to notify You of the nature and extent of such disclosure (to the extent We know that information) as soon as reasonably possible and as permitted by law.</p>

                        <p>6. COOKIES</p>

                        <p>6.1 To improve the responsiveness of the sites for Our Users, We may use “cookies”, or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the User’s individual interests using the Identified Computer. Unless You voluntarily identify yourself (through registration, for example), We will have no way of knowing who You are, even if We assign a cookie to Your computer. The only personal information a cookie can contain is information You supply. A cookie cannot read data off Your hard drive. Our advertisers may also assign their own cookies to Your browser (if You click on their ads), a process that We do not control.</p>

                        <p>7. UPDATES AND CHANGES TO PRIVACY POLICY</p>

                        <p>7.1 We reserve the right, at any time, to add to, change, update, or modify this Privacy Policy so please review it frequently. If We do, then We will post these changes on this page. In all cases, use of information We collect is subject to the Privacy Policy in effect at the time such information is collected.</p>

                        <p>8. YOUR RIGHTS</p>

                        <p>8.1 You have a right to correct any errors in Your Personal Information available with Us. You may request Us in writing that We cease to use Your Personal Information.</p>

                        <p>9. RESTRICTION OF LIABILITY</p>

                        <p>9.1 Gitty makes no claims, promises or guarantees about the accuracy, completeness, or adequacy of the contents available through the App and expressly disclaims liability for errors and omissions in the contents available through the App.9.2 No warranty of any kind, implied, expressed or statutory, including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given with respect to the contents available through the App or its links to other internet resources as may be available to Your through the App.9.3 Reference in the App to any specific commercial products, processes, or services, or the use of any trade, firm or corporation name is for the information and convenience of the public, and does not constitute endorsement, recommendation, or favoring by Gitty.If You have questions or concerns, feel free to email Us or to correspond at zumbotech@gmail.com and We will attempt to address Your concerns..</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
