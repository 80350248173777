import React, { useEffect } from 'react'

const RefundAndCanc = () => {

    // ********* Scroll Upside ********** //
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <div class="breadcrumbs_aree breadcrumbs_bg" data-bgimg="assets/img/bg/main_bg.png">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumbs_text text-center">
                                <h2>Terms and Conditions </h2>
                                <ul class="d-flex justify-content-center">
                                    <li><a href="/">Home </a></li>
                                    <li> <span>//</span></li>
                                    <li> Terms and conditions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page_wrapper">


                <section class="common_content_box py-5"><div id="privacypolicy" class=" container" bis_skin_checked="1"><h4>Refund Policy</h4><p>Thanks for being a patron with Gitty Ludo. If you are not entirely satisfied with your subscription, we are here to help.</p><h4 class="mt-3">Refund</h4><p>Once we receive your Refund request, we will inspect it and notify you on the status of your refund.</p><p>If your refund request is approved, we will initiate a refund to your credit card (or original method of payment) within 7 working days. You will receive the credit within a certain amount of days, depending on your card issuer's policies.</p><p>In case of unforeseen technical glitch, Gitty Ludo would refund subscription upon reviewing the complaint. Final decision lies with the company.</p></div></section>


            </div>


        </>
    )
}

export default RefundAndCanc
